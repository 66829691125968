import React from 'react'
import Card from '../../components/Card/Card'
import { MDBRow, MDBBtn } from 'mdbreact'
import images from '../../images'
import projects from './data/PersonalProjects'
import './PersonalProjects.css'

// i18n
import i18n from '../../i18n'
import { withNamespaces } from 'react-i18next'

const PersonalProjects = ({ t }) => {
  const selectedLang = i18n.languages[0]

  return (
    <div className="personal-projects">
      <h2 className="h1-responsive text-center font-weight-bold mb-5">
        {t('PersonalProjects.title')}
      </h2>
      <p className="lead grey-text w-responsive mx-auto mb-5 text-justify">
        {t('PersonalProjects.description')}
      </p>
      <MDBRow className="m-0" center>
        {
          projects.map((project, index) => (
            <div key={`project-${index}`}>
              <Card
                title={t(`PersonalProjects.${project.title}`)}
                image={project.image}
                description={project.description}
                clickText={selectedLang === 'en' ? 'Take a look' : 'Ver'}
              >
                <p className="lead grey-text w-responsive mx-auto mb-5 text-justify text-container ">
                  {t(`PersonalProjects.${project.text}`)}
                </p>
                {/* <MDBBtn onClick={() => {}}>Tutorial</MDBBtn> */}
                {
                  project.github_url &&
                  <MDBBtn
                    onClick={() =>
                      window.open(project.github_url, '_blank')
                    }
                  >
                    GitHub Repo
                  </MDBBtn>
                }
                {
                  project.demo_url &&
                  <MDBBtn
                    onClick={() =>
                      window.open(project.demo_url, '_blank')
                    }
                  >
                    Live Demo
                  </MDBBtn>
                }
                {
                  project.playstore_url &&
                  <a
                    href={project.playstore_url}
                    target="_blank"
                  >
                    <img
                      alt="Get it on Google Play"
                      src={images.googlePlayBadge}
                      style={{ width: '175px' }}
                    />
                  </a>
                }

              </Card>
            </div>
          ))
        }
      </MDBRow>
    </div>
  )
}

export default withNamespaces()(PersonalProjects)
