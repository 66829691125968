import images from '../../../images';

export default [
	{
		title: 'personalWebSite_title',
		description: 'React, Gatsby.',
		text: 'personalWebSite_text',
		demo_url: 'https://chrisureza.com',
		image: images.portfolio
	},
	{
		title: 'toDoList_title',
		description: 'Vue, Vuex.',
		text: 'toDoList_text',
		github_url: 'https://github.com/chrisureza/Vue-ToDoList',
		demo_url: 'https://vue-vuex-todo.web.app',
		image: images.toDoList
	},
	{
		title: 'webChat_title',
		description: 'React, Firebase (Storage, Authentication, Hosting).',
		text: 'webChat_text',
		github_url: 'https://github.com/chrisureza/React-Chat',
		demo_url: 'https://react-chat-8e594.web.app',
		image: images.webChat
	},
	{
		title: 'flutterMondayMovies_title',
		description: 'Flutter (for Android and iOS).',
		text: 'flutterMondayMovies_text',
		github_url: 'https://github.com/chrisureza/Flutter-Movies',
		playstore_url: 'https://play.google.com/store/apps/details?id=com.chrisureza.mondaymovies',
		image: images.mondayMovies
	},
	{
		title: 'flutterDesigns_title',
		description: 'Designs to use with Flutter.',
		text: 'flutterDesigns_text',
		github_url: 'https://github.com/chrisureza/Flutter-Designs',
		playstore_url: 'https://play.google.com/store/apps/details?id=com.chrisureza.flutter_designs',
		image: images.flutterDesigns
	},
	{
		title: 'flutterQRReader_title',
		description: 'Flutter, QR Reader, SQL Lite, Maps, Streams.',
		text: 'flutterQRReader_text',
		github_url: 'https://github.com/chrisureza/Flutter-QR_Reader',
		playstore_url: 'https://play.google.com/store/apps/details?id=com.chrisureza.qr_reader_app',
		image: images.flutterQRReader2
	},
	{
		title: 'flutterUserPreferences_title',
		description: 'Shared Preferences, Drawer, Singleton.',
		text: 'flutterUserPreferences_text',
		github_url: 'https://github.com/chrisureza/Flutter-User_Preferences',
		image: images.flutterUserPreferences
	},
	{
		title: 'flutterProductMarket_title',
		description: 'RxDart, BLOC, CRUD, AUTH, Camera.',
		text: 'flutterProductMarket_text',
		github_url: 'https://github.com/chrisureza/Flutter-Product_Market',
		image: images.flutterProductMarket
	},
	{
		title: 'flutterNewsApp_title',
		description: 'Provider Pattern, API Calls.',
		text: 'flutterNewsApp_text',
		github_url: 'https://github.com/chrisureza/Flutter-News_App',
		image: images.flutterNewsApp
	},
	{
		title: 'gatsbyWebResume_title',
		description: 'Gatsby, React.',
		text: 'gatsbyWebResume_text',
		github_url: 'https://github.com/chrisureza/Gatsby--Web_Resume',
		demo_url: 'https://gatsby-example-web-resume.netlify.app',
		image: images.gatsbyWebResume
	},
	{
		title: 'reactNativeLoansCalculator_title',
		description: 'React Native.',
		text: 'reactNativeLoansCalculator_text',
		github_url: 'https://github.com/chrisureza/ReactNative__Calcular-Prestamos',
		image: images.noImage
	},
	{
		title: 'nodeConsoleTODO_title',
		description: 'NodeJS Console App.',
		text: 'nodeConsoleTODO_text',
		github_url: 'https://github.com/chrisureza/NodeJS__TODO-Console-App',
		image: images.noImage
	},
	{
		title: 'nodeConsoleWeather_title',
		description: 'NodeJS Console App.',
		text: 'nodeConsoleWeather_text',
		github_url: 'https://github.com/chrisureza/NodeJS__Weather-Console-App',
		image: images.noImage
	},
];