import React from 'react'
import Layout from '../components/Layout/Layout'
import { MDBContainer } from 'mdbreact'
import PersonalProjects from '../components/PersonalProjects/PersonalProjects'

const Projects = () => {
  return (
    <>
      <Layout>
        <main>
          <section id="cardSection">
            <MDBContainer tag="section" className="text-center my-5">
              <PersonalProjects />
            </MDBContainer>
          </section>
        </main>
      </Layout>
    </>
  )
}

export default Projects
